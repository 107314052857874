import ServiceIntro from './ServiceIntro';
import ServiceForm from './ServiceForm';

const MainPage = () => {
  return (
    <>
      <ServiceIntro />
      <ServiceForm />
    </>
  );
};

export default MainPage;
