import i18n, {use} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector, {CustomDetector} from 'i18next-browser-languagedetector';

import enCommon from './en/common.json';
import frCommon from './fr/common.json';
import nlCommon from './nl/common.json';
import dkCommon from './dk/common.json';
import deCommon from './de/common.json';

const topDomainDetector: CustomDetector = {
    name: 'topDomainDetector',
    lookup: (options) => {
        const re = /\.([^.]+?)$/;
        const domainReg = re.exec(window.location.hostname);
        if (domainReg) {
            const domain = domainReg[1];
            switch (domain) {
                case 'dk':
                    return 'dk';
                case 'de':
                case 'at':
                    return 'de';
                case 'nl':
                case 'be':
                    return 'nl';
                case 'fr':
                    return 'fr';
                default:
                    return 'en';
            }
        }
        return 'en';
    }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(topDomainDetector);

export const resources = {
    en: {
        common: enCommon,
    },
    fr: {
        common: frCommon,
    },
    dk: {
        common: dkCommon,
    },
    nl: {
        common: nlCommon,
    },
    de: {
        common: deCommon,
    },
};

i18n
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        resources,
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: [ 'cookie', 'localStorage', 'topDomainDetector', 'querystring', 'navigator', 'htmlTag'],
        },
        debug: true,
    });

export default i18n;