export const generateWoodPageHref = (language: string) => {
  switch (language) {
    case 'de':
      return 'https://gadero.de/eigenschaften-holz/';

    case 'fr':
      return 'https://gadero.fr/proprietes-du-bois/';

    case 'en':
      return 'https://gadero.co.uk/timber-standards/';

    case 'dk':
      return 'https://gadero.dk/karakteristika-trae/';

    default:
      return 'https://gadero.nl/kenmerken-hout/';
  }
};
