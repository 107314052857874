import Frame from '../shared/ui/layout/frame/frame';

interface Props {
  language: string;
}

export const VideoSection = ({language}: Props) => {
  const getUrl = () => {
    switch (language) {
      case 'nl':
        return 'https://www.youtube.com/embed/ZZ807tlbucQ?si=rOeiTS7EvkPvYyj-';

      case 'de':
        return 'https://www.youtube.com/embed/OVgSIUeHggk?si=MwaQckPViQJ-G8bL';
    }
  };

  return (
    <div className="col">
      <Frame>
        <iframe
          title="service form information"
          width="560"
          height="315"
          src={getUrl()}
          allow="encrypted-media"
          allowFullScreen
        ></iframe>
      </Frame>
    </div>
  );
};
