import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './shared/i18n/i18n';

import './style.css';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
