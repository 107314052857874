import {changeLanguage} from 'i18next';
import './LanguageSwitcherSelector.css';
import {useTranslation} from 'react-i18next';

const languages = [
  {key: 'nl', description: 'Nederlands'},
  {key: 'en', description: 'English'},
  {key: 'de', description: 'Deutsch'},
  {key: 'fr', description: 'Français'},
  {key: 'dk', description: 'Dansk'},
];

const LanguageSwitcherSelector = () => {
  const {i18n} = useTranslation();

  const handleChange = (e: any) => {
    changeLanguage(e.target.className);
  };

  const options = languages.map((language) => {
    if (language.key !== i18n.language) {
      return (
        <li key={language.key} >
          <button className={language.key} title={language.description} onClick={handleChange}></button>
        </li>
      );
    }

    return null;
  });

  return (
    <div className="lang">
      <button className={i18n.language}></button>
      <ul className="dropdown">{options}</ul>
    </div>
  );
};

export default LanguageSwitcherSelector;
