import React from 'react';
import {useTranslation, Trans} from 'react-i18next';

import {generateWoodPageHref} from '../shared/helperFunctions/generateWoodPageHref';

import {VideoSection} from './Video-section';

const ServiceIntro = () => {
  const {t, i18n} = useTranslation('common');

  return (
    <>
      <div className="row">
        <div className="col">
          <h1 className="fw-bold">{t('intro.serviceForm')}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div>
            <p>{t('intro.introInformation')}</p>
          </div>
          <div>
            <p>
              <Trans
                t={t}
                i18nKey={'intro.woodInformation'}
                components={{
                  link1: (
                    <a
                      href={generateWoodPageHref(i18n.language)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {''}
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
        {['nl', 'de'].includes(i18n.language) ? (
          <VideoSection language={i18n.language} />
        ) : null}
      </div>
    </>
  );
};

export default ServiceIntro;
