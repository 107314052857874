import {BrowserRouter, Route, Routes} from 'react-router-dom';

import MainPage from './components/MainPage';
import SuccessPage from './components/SuccessPage';
import ServiceHeader from './components/ServiceHeader';

const App = () => {
  return (
    <>
      <ServiceHeader />
      <div className="container">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="success" element={<SuccessPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
