import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {generateWoodPageHref} from '../shared/helperFunctions/generateWoodPageHref';

const SuccessPage = () => {
  const {t, i18n} = useTranslation('common');

  return (
    <>
      <h1>{t('intro.serviceForm')}</h1>
      <div id="intro">
        <div className="alert alert-success">{t('formReceivedSuccessfully')}</div>
        <p>{t('formLabel.responseMessage')}</p>
        <p>
          <Trans
            t={t}
            i18nKey={'redirectForTips'}
            components={{
              link1: (
                <a
                  href={generateWoodPageHref(i18n.language)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {''}
                </a>
              ),
            }}
          />
        </p>
      </div>
    </>
  );
};

export default SuccessPage;
