import React from 'react';
import {useTranslation} from 'react-i18next';

import LanguageSwitcherSelector from './LanguageSwitcherSelector';

const ServiceHeader = () => {
  const {i18n} = useTranslation();

  const generateHref = (language: string) => {
    switch (language) {
      case 'de':
        return 'https://gadero.de';

      case 'fr':
        return 'https://gadero.fr';

      case 'en':
        return 'https://gadero.co.uk';

      case 'dk':
        return 'https://gadero.dk';

      default:
        return 'https://gadero.nl';
    }
  };

  return (
    <>
      <div className="container headerImage">
        <a href={generateHref(i18n.language)} target="_blank" rel="noreferrer">
          <img src={'assets/header_logo_gadero.png'} alt="Gadero" id="header_logo" />
        </a>
        <LanguageSwitcherSelector />
      </div>
      <div className="servicePageBanner"></div>
    </>
  );
};

export default ServiceHeader;
