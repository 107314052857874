import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import {BaseSyntheticEvent} from 'react';
import {useNavigate} from 'react-router-dom';

type FormData = {
  complaint: string;
  firstName: string;
  lastName: string;
  city: string;
  phoneNumber: string;
  email: string;
  orderNumber: string;
  product: string;
  length: string;
  width: string;
  description: string;
  photo1: FileList;
  photo2: FileList;
  photo3: FileList;
  photo4: FileList;
  photo5: FileList;
};

// this is a hack to make it so the app redirects to the succes page instead of throwing an error
axios.interceptors.response.use(
  function () {
    window.location.pathname = '/success';
  },
  function (error) {
    if (
      error.request?.responseURL === 'https://service-formulier.gadero.nl/succes.html'
    ) {
      window.location.pathname = '/success';
    }
  }
);

const postAPIUrl = process.env.REACT_APP_service_post_api || '';

const ServiceForm = () => {
  const {t} = useTranslation('common');
  const navigate = useNavigate();

  const {register, handleSubmit} = useForm<FormData>();

  const onSubmit = async (data: FormData, event?: BaseSyntheticEvent) => {
    const formData = new FormData(event?.target);

    try {
      const response = await axios.post(postAPIUrl, formData);

      if (['2', '3'].includes(String(response.status).charAt(0))) {
        navigate('success');
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="row">
      <div className="col">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p>{t('formInfo.formFillRequest')}</p>
          </div>
          <div className="mb-3">
            <label htmlFor="inputComplaintType" className="form-label">
              {t('formInfo.serviceTopicSelectMessage')}
            </label>
            <select
              {...register('complaint')}
              className="form-select"
              id="inputComplaintType"
            >
              <option>{t('formInfo.receivedDamagedProduct')}</option>
              <option>{t('formInfo.incompleteDelivery')}</option>
              <option>{t('formInfo.receivedWrongProduct')}</option>
              <option>{t('formInfo.others')}</option>
            </select>
          </div>
          <h2>{t('formLabel.contactDetails')}</h2>
          <div className="mb-3">
            <label htmlFor="inputFirstName" className="form-label">
              <b>{t('formLabel.firstName')}</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputFirstName"
              {...register('firstName', {required: true})}
              placeholder={t('formLabel.firstName')}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputLastName" className="form-label">
              <b>{t('formLabel.lastName')}</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputLastName"
              {...register('lastName', {required: true})}
              placeholder={t('formLabel.lastName')}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputCity" className="form-label">
              <b>{t('formLabel.residence')}</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputCity"
              placeholder={t('formLabel.residence')}
              {...register('city', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputPhoneNumber" className="form-label">
              <b>{t('formLabel.phoneNumber')}</b>: *
            </label>
            <input
              type="tel"
              className="form-control"
              id="inputPhoneNumber"
              placeholder={t('formLabel.phoneNumber')}
              {...register('phoneNumber', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputEmail" className="form-label">
              <b>{t('formLabel.email')}</b>: *
            </label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder={t('formLabel.email')}
              {...register('email', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputOrderNumber" className="form-label">
              <b>{t('formLabel.orderNumber')} Gadero</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputOrderNumber"
              placeholder={t('formLabel.orderNumber')}
              {...register('orderNumber', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputEmail" className="form-label">
              <b>{t('formLabel.article')}</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputProduct"
              placeholder={t('formLabel.articlePlaceholder')}
              {...register('product', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputEmail" className="form-label">
              <b>{t('formLabel.length')}</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputLength"
              placeholder=""
              {...register('length', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputEmail" className="form-label">
              <b>{t('formLabel.width')}</b>: *
            </label>
            <input
              type="text"
              className="form-control"
              id="inputWidth"
              placeholder=""
              {...register('width', {required: true})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="inputProblemDescription" className="form-label">
              {t('formLabel.problemDescriptionMessage')} *
            </label>
            <textarea
              className="form-control"
              id="inputProblemDescription"
              placeholder={t('formLabel.generalProblemDescription')}
              {...register('description', {required: true})}
            ></textarea>
          </div>
          <div className="mb-3">
            <p>{t('formLabel.photoInformationMessage')}</p>
            <label htmlFor="photoInput1" className="form-label text-muted">
              {t('formLabel.sendPhotoMessage')}
            </label>
            <input
              type="file"
              className="col-6 form-control"
              id="photoInput1"
              accept="image/gif, image/jpeg, image/png"
              {...register('photo1')}
            />
            <label htmlFor="photoInput2" className="form-label text-muted">
              {t('formLabel.sendPhotoMessage')}
            </label>
            <input
              type="file"
              className="col-6 form-control"
              id="photoInput2"
              {...register('photo2')}
              accept="image/gif, image/jpeg, image/png"
            />
            <label htmlFor="photoInput3" className="form-label text-muted">
              {t('formLabel.sendPhotoMessage')}
            </label>
            <input
              type="file"
              className="col-6 form-control"
              id="photoInput3"
              {...register('photo3')}
              accept="image/gif, image/jpeg, image/png"
            />
            <label htmlFor="photoInput4" className="form-label text-muted">
              {t('formLabel.sendPhotoMessage')}
            </label>
            <input
              type="file"
              className="col-6 form-control"
              id="photoInput4"
              {...register('photo4')}
              accept="image/gif, image/jpeg, image/png"
            />
            <label htmlFor="photoInput5" className="form-label text-muted">
              {t('formLabel.sendPhotoMessage')}
            </label>
            <input
              type="file"
              className="col-6 form-control"
              id="photoInput5"
              {...register('photo5')}
              accept="image/gif, image/jpeg, image/png"
            />
          </div>
          <div className="mb-3">
            <p>{t('formLabel.responseMessage')}</p>
            <input
              type="submit"
              className="btn btn-primary"
              value={t('formLabel.send')}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ServiceForm;
