import styled from 'styled-components';

export interface FrameProps {
  ratio?: string;
}

const Frame = styled.div<FrameProps>`
  aspect-ratio: ${props => props.ratio || '16 / 9'};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  > img,
  > video {
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
  }
`;

export default Frame;
